<template>
  <div class="question_container">
    <div class="question_answers">
      <characteristics-of-job-questions></characteristics-of-job-questions>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript" ></question-info>
    </div>
  </div>
  <navigation></navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import CharacteristicsOfJobQuestions from "@/components/questions/CharacteristicsOfJobQuestions";
import QuestionInfo from "@/components/QuestionInfo";

export default {
  name: "CharacteristicsOfJob",
  components: {CharacteristicsOfJobQuestions, Navigation, QuestionInfo},
  setup(){
    const questionDescript = "At forstå din Buyer Personas mål, udfordringer og daglige ansvar fortæller dig, hvad du " +
        "kan gøre for at hjælpe dem med at overvinde forhindringer og forenkle deres arbejdsgang. Det hjælper dig også " +
        "med at udvikle empati, som er en stærk kraft, når det kommer til at få dem til at handle."

    return{
      questionDescript
    }
  }
}
</script>

<style scoped>
</style>