<template>
  <h1>Trin 5/7: Karakteristika ved deres job</h1>
  <div>
    <div>
      <h3>Hvad er deres mål eller fokus?</h3>
      <span class="p-float-label">
        <InputText id="goalsOrObjectives" v-model="goalsOrObjectives" type="text"/>
        <label for="goalsOrObjectives">F.eks.: Leads, indtægter osv.</label>
      </span>
    </div>
    <div>
      <h3>Hvad er deres største udfordringer?</h3>
      <div class="cb_list">
        <div v-for="challange of listBiggestChallenges" :key="challange.id" class="p-field-checkbox">
          <Checkbox :id="challange.id" name="biggestChallenges" :value="challange.val" v-model="personaBiggestChallenges" />
          <label :for="challange.id">{{ challange.val }}</label>
        </div>
      </div>
      <div>
        <span class="p-float-label">
        <InputText id="additionalChallange" type="text" @keydown="addAdditionalChallange" v-model="additionalChallenge"/>
        <label for="additionalChallange">Hvis ingen af ovenstående muligheder passer, kan du tilføje en her og trykke på enter.</label>
      </span>
      </div>
    </div>
    <div>
      <h3>Hvad er deres jobansvar?</h3>
      <span class="p-float-label">
        <InputText id="jobResponsibilities" v-model="jobResponsibilities" type="text"/>
        <label for="jobResponsibilities">F.eks.: Personaleadministration, oprettelse af indhold osv</label>
      </span>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import store from "@/store/store";
export default {
  name: "CharacteristicsOfJobQuestions",
  components: {},
  setup(){

    const goalsOrObjectives = computed({
      get: () => store.state.goalsOrObjectives,
      set: val => {
        store.dispatch(('setGoalsOrObjectives'), {goalsOrObjects: val})
      }
    })

    const listBiggestChallenges = computed( () => { return store.state.listBiggestChallenges})

    const personaBiggestChallenges = computed({
      get: () => store.state.personaBiggestChallenges,
      set: val => {
        store.dispatch(('setPersonaBiggestChallenges'), {biggestChallenges: val})
      }
    })

    let additionalChallenge = ref("");

    const jobResponsibilities = computed({
      get: () => store.state.jobResponsibilities,
      set: val => {
        store.dispatch(('setJobResponsibilities'), {responsibilities: val})
      }
    })

    function addAdditionalChallange(e) {
      if(e.keyCode === 13) {
        const challangeId = additionalChallenge.value.replace(' ', '-')
        const challange = {id: challangeId, val: additionalChallenge.value}

        let challangeFound = listBiggestChallenges.value.find( challange => challange.id === challangeId )
        if(!challangeFound) {
          store.dispatch(('updateListBiggestChallenges'), {additionalChallenge: challange})
          store.dispatch(('updatePersonaBiggestChallenges'), {challenge: challange.val})
          additionalChallenge.value = "";
        }
      }
    }

    return{
      goalsOrObjectives,
      personaBiggestChallenges,
      listBiggestChallenges,
      additionalChallenge,
      jobResponsibilities,
      addAdditionalChallange
    }
  }
}
</script>

<style scoped>
</style>